import React, { useEffect, useState } from 'react'
import {
  Divider,
  Form,
  Row,
  Input,
  Col,
  DatePicker,
  InputNumber,
  Button,
  Modal,
  Radio,
  Tree,
  ColorPicker,
  Checkbox,
  Tooltip,
  Select,
  Spin,
} from 'antd'
import { useTranslation } from 'react-i18next'
import localeEnGb from 'antd/es/date-picker/locale/en_GB'
import localeFrFr from 'antd/es/date-picker/locale/fr_FR'
import localeUkUa from 'antd/es/date-picker/locale/uk_UA'
import localeDeDe from 'antd/es/date-picker/locale/de_DE'
import localeItIt from 'antd/es/date-picker/locale/it_IT'
import dayjs, { Dayjs } from 'dayjs'
import duration from 'dayjs/plugin/duration'
import TextAreaEditor from '../../../components/TextAreaEditor/TextAreaEditor'
import { AppDispatch, RootState } from '../../../store'
import { useDispatch, useSelector } from 'react-redux'
import { AiOutlineDelete } from 'react-icons/ai'
import { RoomToModify, setRoomToModify } from '../MeetingsSlice'
import {
  createRoom,
  updateRoom,
  idleCreateRoomStatus,
  idleUpdateRoomStatus,
  setNewMeeting,
  addParticipant,
  addInvited,
  deleteInvited,
  deleteParticipant,
  updateParticipantRole,
  updateInvitedRole,
  addUnconfirmedFile,
  removeUnconfirmedFile,
  addDate,
  deleteDate,
  idleUpdatePlanRoomStatus,
  updatePlanRoom,
  Days,
  Periodicity,
  Frequency as strFrequency,
  createPeriodicRoom,
  idleCreatePeriodicRoomStatus,
  clearRoomDuplicate,
} from '../NewMeetingSlice'
import DocumentsUpload from '../../../components/DocumentsUpload/DocumentsUpload'
import DocumentsList from '../../../components/DocumentsList/DocumentsList'
import { RoomFile, RoomType } from '../../room/RoomInterface'
import './new-meeting.scss'
import '../meetings.scss'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import TimeZoneVisualizer from '../../../components/TimeZoneVisualizer/TimeZoneVisualizer'
import { useAttemptsListener } from 'auxasphere-react-kit'
import ManageParticipants from '../../../components/RoomManage/ManageParticipants/ManageParticipants'
import { meetingColor } from '../../../components/RoomManage/RoomManage'
import { ColorFactory } from 'antd/lib/color-picker/color'
import useDatesTreeOptions from '../../../utils/hooks/useDatesTreeOptions'
import { BiInfoCircle } from 'react-icons/bi'
import '../../../index.scss'
import PeriodicityForm from '../PeriodicityForm'
import { RRule, Weekday, Frequency as RRFrequency, ByWeekday } from 'rrule'
import TagReference from './TagReference'
import { unescape } from 'html-escaper'
import { useToastContext } from '../../../components/Toast/ToastContext'

export type FormValues = {
  name: string
  color: ColorFactory
  type: RoomType
  reference?: Array<string>
  duration: number
  creatorNote: string
  startDate: Dayjs
}

export const defaultByWeekDay: { label: Days; value: Weekday }[] = [
  { label: 'monday', value: RRule.MO },
  { label: 'tuesday', value: RRule.TU },
  { label: 'wednesday', value: RRule.WE },
  { label: 'thursday', value: RRule.TH },
  { label: 'friday', value: RRule.FR },
  { label: 'saturday', value: RRule.SA },
  { label: 'sunday', value: RRule.SU },
]
export const defaultFrequency: { label: strFrequency; value: RRFrequency }[] = [
  { label: 'day', value: RRule.DAILY },
  { label: 'week', value: RRule.WEEKLY },
  { label: 'month', value: RRule.MONTHLY },
  { label: 'year', value: RRule.YEARLY },
]

interface Props {
  meetingStartDate?: Dayjs
  fromCalendar?: boolean
  meetingDuration?: number
  onMeetingCreated: () => void
  onPendingMeetingCreated: () => void
  onMeetingCancel: () => void
}

export function getDatePickerLocale(language: string) {
  switch (language) {
    case 'fr':
      return localeFrFr
    case 'de':
      return localeDeDe
    case 'it':
      return localeItIt
    case 'uk':
      return localeUkUa
    default:
      return localeEnGb
  }
}

function NewMeetingForm({
  meetingStartDate,
  meetingDuration,
  onMeetingCreated,
  onPendingMeetingCreated,
  onMeetingCancel,
  fromCalendar,
}: Props) {
  const email = useSelector((state: RootState) => state.auth.email) || []
  const [datePickerValue, setDatePickerValue] = useState<any>()
  const [showTimeZone, setShowTimezone] = useState(false)
  const [hideDateOptions, setHideDateOptions] = useState(true)
  const MAX_DATES_ARRAY = 10
  const [isChecked, setChecked] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const { ToastOpen } = useToastContext()
  const { t, i18n } = useTranslation('meetings')
  let datePickerLocale = getDatePickerLocale(i18n.language)
  dayjs.extend(duration)

  const roomToCreate = useSelector((state: RootState) => state.newMeeting.roomToCreate)
  const plannedDates = useSelector((state: RootState) => state.newMeeting.plannedDates)
  const createRoomStatus = useSelector(
    (state: RootState) => state.newMeeting.createRoomStatus,
  )
  const updateRoomStatus = useSelector(
    (state: RootState) => state.newMeeting.updateRoomStatus,
  )
  const updatePlanRoomStatus = useSelector(
    (state: RootState) => state.newMeeting.updatePlanRoomStatus,
  )
  const createPeriodicRoomStatus = useSelector(
    (state: RootState) => state.newMeeting.createPeriodicRoomStatus,
  )

  const [newMeetingForm] = Form.useForm()

  const [confirmCreateRoom, setConfirmCreateRoom] = useState<FormValues | undefined>(
    undefined,
  )

  const disabled = updateRoomStatus === 'loading' || updatePlanRoomStatus === 'loading'

  const datesTreeChildren = (plannedDates ?? []).map((timestamp: number) => {
    return {
      title: () => {
        return (
          <div className="tree-dates d-flex d-flex-center">
            <span>{dayjs(timestamp).format('LLL')}</span>
            <span
              className="d-flex d-flex-middle"
              onClick={() => dispatch(deleteDate(timestamp))}
            >
              <AiOutlineDelete />
            </span>
          </div>
        )
      },
      key: timestamp,
    }
  })
  const { tree: datesTreeOptions } = useDatesTreeOptions(datesTreeChildren)

  const [personalizePeriodicity, setPersonalizePeriodicity] = useState<
    Periodicity | undefined
  >(undefined)
  /**
   * OnMount : Create a new meeting
   * OnUnmount : Reset the new meeting state
   */
  useEffect(() => {
    dispatch(createRoom())

    return () => {
      dispatch(setNewMeeting())
      dispatch(clearRoomDuplicate())
    }
  }, [])

  /**
   * Catch room creation errors
   */
  useEffect(() => {
    switch (createRoomStatus) {
      case 'error':
        ToastOpen({
          message: t('Error creating meeting.'),
          type: 'error',
        })
        break
    }

    return () => {
      dispatch(idleCreateRoomStatus())
    }
  }, [createRoomStatus])

  useEffect(() => {
    if (newMeetingForm.getFieldValue('duration') == 1440) {
      setChecked(true)
    }
  }, [])

  useAttemptsListener(
    [
      [
        updateRoomStatus,
        {
          success: () => {
            ToastOpen({
              message: t('Meeting was successfully created.'),
              type: 'success',
            })
            onMeetingCreated()
          },
          error: () => {
            ToastOpen({
              message: t('Error creating meeting.'),
              type: 'error',
            })
          },
        },
      ],
      [
        updatePlanRoomStatus,
        {
          success: () => {
            ToastOpen({
              message: t('Meeting was successfully created.'),
              type: 'success',
            })
            onPendingMeetingCreated()
          },
          error: () => {
            ToastOpen({
              message: t('Error creating meeting.'),
              type: 'error',
            })
          },
        },
      ],
      [
        createPeriodicRoomStatus,
        {
          success: () => {
            ToastOpen({
              message: t('Meeting was successfully created.'),
              type: 'success',
            })
            onMeetingCreated()
          },
          error: () => {
            ToastOpen({
              message: t('Error creating meeting.'),
              type: 'error',
            })
          },
        },
      ],
    ],

    () => {
      dispatch(idleUpdatePlanRoomStatus())
      dispatch(idleUpdateRoomStatus())
      dispatch(idleCreatePeriodicRoomStatus())
      dispatch(setRoomToModify())
    },
  )

  const onCheckboxChange = () => {
    const defaultDuration = isChecked ? 30 : 1440
    const tempDate = newMeetingForm.getFieldValue('startDate') || dayjs()

    newMeetingForm.setFieldValue('duration', defaultDuration)
    newMeetingForm.setFieldValue(
      'startDate',
      tempDate.set('hour', 0).set('minute', 0).set('second', 0),
    )
    setChecked(!isChecked)
  }

  const onAddDate = (startDate: Dayjs) => {
    if (!startDate || hideDateOptions) return
    if (plannedDates?.find((d) => d === new Date(startDate.toISOString()).getTime())) {
      ToastOpen({
        message: t('This date is already selected.'),
        type: 'info',
      })
    } else if (plannedDates && plannedDates?.length > MAX_DATES_ARRAY - 1) {
      ToastOpen({
        message: t('You cannot add more dates.'),
        type: 'error',
      })
    } else {
      dispatch(addDate(startDate.toISOString()))
    }
  }

  const addFile = (file: RoomFile) => {
    dispatch(
      addUnconfirmedFile({
        roomId: roomToCreate?.id || '',
        file: file,
      }),
    )
  }

  const removeFile = (fileUid: string) => {
    dispatch(
      removeUnconfirmedFile({
        roomId: roomToCreate?.id || '',
        fileUid: fileUid,
      }),
    )
  }

  const sendCreateRoom = () => {
    if (confirmCreateRoom !== undefined) {
      const newRoom: RoomToModify = {
        id: roomToCreate?.id || '',
        name: confirmCreateRoom['name'],
        color: confirmCreateRoom['color'].toHexString?.(),
        type: confirmCreateRoom['type'],
        reference: confirmCreateRoom['reference'] || [],
        duration: confirmCreateRoom['duration'],
        creatorNote: confirmCreateRoom['creatorNote'],
        participants: roomToCreate?.participants || [],
        invited: roomToCreate?.invited || [],
        unconfirmedFiles: roomToCreate?.unconfirmedFiles || [],
        unconfirmedFileDeletions: roomToCreate?.unconfirmedFileDeletions || [],
        periodicity: personalizePeriodicity
          ? new RRule({
              dtstart: dayjs(confirmCreateRoom['startDate']).utc(true).toDate(),
              interval: personalizePeriodicity?.interval,
              freq: defaultFrequency.find(
                (item) => item.label == personalizePeriodicity?.freq,
              )?.value,
              byweekday: defaultByWeekDay
                .filter((item) => personalizePeriodicity?.byweekday?.includes(item.label))
                .map((item) => item.value),
              until: personalizePeriodicity?.until
                ? dayjs(personalizePeriodicity?.until).toDate()
                : undefined,
              count: personalizePeriodicity?.count,
              bymonth: personalizePeriodicity.bymonth,
              bymonthday: personalizePeriodicity.bymonthday,
              bysetpos: personalizePeriodicity?.bysetpos,
            }).toString()
          : undefined,
      }
      if (hideDateOptions) {
        if (newRoom.periodicity) {
          dispatch(createPeriodicRoom(newRoom))
        } else {
          dispatch(
            updateRoom({
              ...newRoom,
              startDate: confirmCreateRoom['startDate'].millisecond(0).toISOString(),
            }),
          )
        }
      } else {
        dispatch(updatePlanRoom({ room: newRoom, availableDates: plannedDates || [] }))
      }
    }
  }

  const onMeetingCreate = async (values: FormValues) => {
    try {
      if (!hideDateOptions) {
        if (plannedDates?.length === 0) {
          ToastOpen({
            message: t('Please add at least one date.'),
            type: 'error',
          })
          return
        }
      }
    } catch {
      return
    }

    if (!roomToCreate?.participants && !roomToCreate?.invited) {
      setConfirmCreateRoom(values)
      return
    }

    const newRoom: RoomToModify = {
      id: roomToCreate?.id || '',
      name: values['name'],
      color: values['color'].toHexString?.(),
      type: values['type'],
      reference: values['reference'] || [],
      duration: values['duration'],
      creatorNote: values['creatorNote'],
      participants: roomToCreate?.participants || [],
      invited: roomToCreate?.invited || [],
      unconfirmedFiles: roomToCreate?.unconfirmedFiles || [],
      unconfirmedFileDeletions: roomToCreate?.unconfirmedFileDeletions || [],
      periodicity: personalizePeriodicity
        ? new RRule({
            dtstart: dayjs(values['startDate']).utc(true).toDate(),
            interval: personalizePeriodicity?.interval,
            freq: defaultFrequency.find(
              (item) => item.label == personalizePeriodicity?.freq,
            )?.value,
            byweekday: defaultByWeekDay
              .filter((item) => personalizePeriodicity?.byweekday?.includes(item.label))
              .map((item) => item.value),
            until: personalizePeriodicity?.until
              ? dayjs(personalizePeriodicity?.until).toDate()
              : undefined,
            count: personalizePeriodicity?.count,
            bymonth: personalizePeriodicity.bymonth,
            bymonthday: personalizePeriodicity.bymonthday,
            bysetpos: personalizePeriodicity?.bysetpos,
          }).toString()
        : undefined,
    }
    if (hideDateOptions) {
      if (newRoom.periodicity) {
        dispatch(createPeriodicRoom(newRoom))
      } else {
        dispatch(updateRoom({ ...newRoom, startDate: values['startDate'].toISOString() }))
      }
    } else {
      dispatch(updatePlanRoom({ room: newRoom, availableDates: plannedDates || [] }))
    }
  }

  return (
    <>
      <div className="pb-5rem">
        {roomToCreate?.id ? (
          <div className="new-meeting">
            <Form
              form={newMeetingForm}
              onFinish={onMeetingCreate}
              initialValues={{
                name: roomToCreate.name,
                color: roomToCreate?.color ?? meetingColor[0].value,
                creator: email,
                duration: roomToCreate?.duration ?? meetingDuration ?? 30,
                creatorNote: roomToCreate?.creatorNote ?? '',
                startDate: meetingStartDate ?? null,
                type: roomToCreate?.type ?? RoomType.Videoconferencing,
              }}
              name="general"
              autoComplete="off"
              layout="vertical"
              scrollToFirstError={true}
            >
              <Row>
                <div className="layout-header-bottom">
                  <h4 className="layout-list-header">{t('General')}</h4>
                </div>
              </Row>
              <Divider />
              <Row gutter={[24, 8]} className="form-meeting-container">
                <Col span={20}>
                  <Form.Item
                    style={{ width: '100%' }}
                    label={t('Title')}
                    name="name"
                    rules={[{ required: true, message: t('Please input title') }]}
                  >
                    <Input autoFocus />
                  </Form.Item>
                </Col>
                <Col sm={4} className="d-flex justify-content-end d-flex-column">
                  <div className="d-flex d-flex-middle">
                    <span className="mb-02em mr-05rem text-color pointer-events-none">
                      {t('Color', { ns: 'common' })}
                    </span>
                    <Tooltip
                      title={t('Customize your own planning')}
                      className="cursor-pointer"
                    >
                      <BiInfoCircle size="1em" className="info-color" />
                    </Tooltip>
                  </div>
                  <Form.Item name="color">
                    <ColorPicker
                      className="new-form-color-picker"
                      panelRender={(_, { components: { Presets } }) => <Presets />}
                      trigger="hover"
                      presets={[
                        {
                          label: '',
                          colors: meetingColor.map((col) => col.value),
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('Type of meeting')} name="type">
                    <Select placeholder={t('Type of meeting')} defaultOpen={false}>
                      <Select.Option value={RoomType.Videoconferencing}>
                        {t('Videoconferencing')}
                      </Select.Option>
                      <Select.Option value={RoomType.Phone}>{t('Phone')}</Select.Option>
                      <Select.Option value={RoomType.InPerson}>
                        {t('In-Person')}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <TagReference initialRef={roomToCreate.reference} />
                <Col span={24}>
                  <Form.Item
                    label={t('Choose a date')}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Radio.Group
                      onChange={(value) => {
                        setHideDateOptions(value.target.value)
                        newMeetingForm.setFieldsValue({ startDate: null })
                      }}
                      value={hideDateOptions}
                    >
                      <Radio value={true}>{t('Unique date')}</Radio>
                      <Radio value={false}>
                        <span className="d-flex d-flex-center d-flex-middle g-0_2rem">
                          {t('Proposed dates')}
                          <Tooltip
                            title={t(
                              'Enables you to carry out a poll to select a meeting date',
                            )}
                            className="cursor-pointer"
                          >
                            <BiInfoCircle size="1.2em" className="info-color" />
                          </Tooltip>
                        </span>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t('Meeting at')}
                    className="meeting-start-date-form-item mb-0"
                    name="startDate"
                    rules={[
                      {
                        required: hideDateOptions ? true : false,
                        message: t('Please input start date'),
                      },
                    ]}
                  >
                    <DatePicker
                      className="w-100"
                      showTime={
                        !isChecked && {
                          defaultValue:
                            hideDateOptions || plannedDates?.length === 0
                              ? dayjs('00:00', 'HH:mm')
                              : dayjs((plannedDates || [0])[0]),
                          format: 'HH:mm',
                          minuteStep: 5,
                        }
                      }
                      showToday={!isChecked}
                      showNow={hideDateOptions}
                      showSecond={false}
                      locale={datePickerLocale}
                      format="DD/MM/YYYY HH:mm"
                      inputReadOnly
                      disabledDate={(current) =>
                        current.isBefore(dayjs().subtract(1, 'day').toString())
                      }
                      onOk={(value) => {
                        if (!hideDateOptions) {
                          newMeetingForm.setFieldsValue({ startDate: null })
                        }
                        onAddDate(value.set('millisecond', 0))
                      }}
                      onChange={setDatePickerValue}
                      placeholder={
                        !hideDateOptions ? t('Add a date below') : t('Select a date')
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24} sm={12}>
                  <Form.Item
                    label={t('Duration')}
                    name="duration"
                    rules={[{ required: true, message: t('Please input duration') }]}
                  >
                    <InputNumber
                      disabled={isChecked}
                      min={1}
                      max={1440}
                      addonAfter="min"
                    />
                  </Form.Item>
                  <Checkbox
                    className="mt-05rem"
                    checked={isChecked}
                    onChange={onCheckboxChange}
                  >
                    {t('All day long')}
                  </Checkbox>
                </Col>
                {hideDateOptions ? (
                  <Col span={24}>
                    <div className="show-timezone-container">
                      <span
                        className="show-timezone-txt"
                        hidden={!datePickerValue}
                        onClick={() => setShowTimezone(!showTimeZone)}
                      >
                        {t('See time for another timezone')}
                        {showTimeZone ? <BsChevronUp /> : <BsChevronDown />}
                      </span>
                    </div>
                    <div hidden={!showTimeZone}>
                      <TimeZoneVisualizer date={datePickerValue} />
                    </div>
                  </Col>
                ) : (
                  <>
                    <Col span={24}>
                      <div className="show-timezone-container">
                        <span
                          className="show-timezone-txt"
                          hidden={!datePickerValue}
                          onClick={() => setShowTimezone(!showTimeZone)}
                        >
                          {t('See time for another timezone')}
                          {showTimeZone ? <BsChevronUp /> : <BsChevronDown />}
                        </span>
                      </div>
                      <div hidden={!showTimeZone}>
                        <TimeZoneVisualizer date={datePickerValue} />
                      </div>
                    </Col>
                    <Col span={24} sm={12}>
                      <Tree
                        showLine={true}
                        switcherIcon={false}
                        defaultExpandedKeys={['parentTree']}
                        selectable={false}
                        treeData={datesTreeOptions}
                      />
                    </Col>
                    <Col span={24} sm={12}>
                      {/* Planner options (unique choice, reminder...) */}
                    </Col>
                  </>
                )}
                {/* <Col span={24} sm={12}>
                  <PeriodicityForm
                    personalizePeriodicity={personalizePeriodicity}
                    setPersonalizePeriodicity={setPersonalizePeriodicity}
                    hidden={!hideDateOptions}
                    defaultDate={datePickerValue}
                  />
                </Col> */}
                <Col span={24}>
                  <Form.Item
                    label={t('Note from the organizer')}
                    name="creatorNote"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <TextAreaEditor />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row>
              <div className="layout-header-bottom mt-1_5rem">
                <h4 className="layout-list-header">{t('Participants')}</h4>
              </div>
            </Row>
            <Divider />
            <ManageParticipants
              room={roomToCreate}
              vertical={false}
              disabled={disabled}
              addParticipant={addParticipant}
              addInvited={addInvited}
              deleteParticipant={deleteParticipant}
              deleteInvited={deleteInvited}
              updateParticipantRole={updateParticipantRole}
              updateInvitedRole={updateInvitedRole}
            />

            <Row>
              <div className="layout-header-bottom mt-1rem">
                <h4 className="layout-list-header">{t('Documents', { ns: 'common' })}</h4>
              </div>
            </Row>
            <Divider />
            <Row
              className="form-meeting-container align-with-others pb-5rem"
              gutter={[8, 8]}
            >
              <Col span={24}>
                <DocumentsUpload
                  room={roomToCreate}
                  title={t('Upload files for this meeting', { ns: 'common' })}
                  immediateSave={false}
                  disabled={disabled}
                  newRoomAddUnconfirmedFile={addFile}
                />
              </Col>
              <Col span={24}>
                <h4 className="uppercase mt-2rem">
                  {t('Available files', { ns: 'common' })}
                </h4>
              </Col>
              <Col span={24}>
                <DocumentsList
                  disabled={disabled}
                  room={roomToCreate}
                  immediateSave={false}
                  newRoomRemoveUnconfirmedFile={removeFile}
                />
              </Col>
            </Row>

            <Row
              className={`form-meeting-container sticky-create-meeting align-with-others ${
                fromCalendar ? 'sticky-create-meeting--calendar' : ''
              }`}
            >
              <Col span={24} className="submit-button">
                <Button
                  type="default"
                  className="mr-1rem"
                  onClick={() => onMeetingCancel()}
                >
                  {t('Cancel', { ns: 'common' })}
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    newMeetingForm.submit()
                  }}
                  disabled={disabled}
                  loading={updateRoomStatus === 'loading'}
                >
                  {t('Create the meeting')}
                </Button>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="d-flex d-flex-center d-flex-middle 2rem">
            <Spin size="large" />
          </div>
        )}

        <Modal
          centered
          className="line-1"
          open={confirmCreateRoom !== undefined}
          title={
            <>
              {t('Manage meeting')}{' '}
              <strong>{unescape(confirmCreateRoom?.name || '')}</strong>
            </>
          }
          onOk={sendCreateRoom}
          onCancel={() => setConfirmCreateRoom(undefined)}
          okText={t('Yes')}
          cancelText={t('Back')}
        >
          <p>{t('There are no participants in the meeting, continue?')}</p>
        </Modal>
      </div>
    </>
  )
}

export default NewMeetingForm
